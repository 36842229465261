var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-nav"},[_vm._m(0),_c('div',{staticClass:"nav-content"},[_c('ul',[_c('li',{staticClass:"nav-item"},[(!_vm.isAdmin)?_c('div',{staticClass:"nav-link red-circle",attrs:{"data-toggle":"modal","data-target":"#exampleModalCenter"},on:{"click":_vm.toggleNotifications}},[_c('img',{attrs:{"src":require("../../assets/images/shock-sjield/notification.png"),"alt":"bell"}}),(_vm.notificationCount)?_c('span',{staticClass:"button__badge"},[_vm._v(_vm._s(_vm.notificationCount))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"modal fade",attrs:{"id":"exampleModalCenter","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalCenterTitle","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(1),_c('div',{staticClass:"modal-body over"},[_vm._m(2),_vm._m(3),_vm._m(4),(_vm.showLoader == 1)?_c('ul',{staticClass:"notifications-list justify-content-center"},[_c('div',{staticClass:"lds-dual-ring-notification",attrs:{"id":"loading"}})]):_vm._e(),(_vm.showLoader != 1)?_c('ul',{staticClass:"notifications-list flex-column align-items-start"},_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.NotificationID,staticClass:"w-100"},[(notification?.Message)?_c('li',{staticClass:"p-3",class:{
                          'type1': notification.notification_type === 1,
                          'type2': notification.notification_type === 2,
                          'type3': notification.notification_type === 3,
                          'type4': notification.notification_type === 4,
                          'type5': notification.notification_type === 5,
                          'type6': notification.notification_type === 6,
                          'type7': notification.notification_type === null,
                        }},[_vm._v(" "+_vm._s(notification.FirstName)+" "+_vm._s(notification.LastName)+" "+_vm._s(notification.Message)+". "),_c('br'),(notification?.order && notification?.order?.order_number)?_c('span',[_vm._v(" Order Number: "),_c('strong',[_vm._v(_vm._s(notification.order.order_number))])]):_vm._e(),_c('p',[_vm._v(" Date: "+_vm._s(_vm._f("moment")(notification.createdAt,"MMM D, YYYY h:mm A"))+" ")])]):_vm._e()])}),0):_vm._e()])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_c('div',{staticClass:"brand"},[_c('img',{staticClass:"navlogo",attrs:{"src":require("../../assets/images/ShockShield.png"),"alt":"ShockShield"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLongTitle"}},[_vm._v(" Notifications ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Labels")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 my-1"},[_c('div',{staticClass:"labels type1"},[_c('span',[_vm._v("Order Creation")])])]),_c('div',{staticClass:"col-4 my-1"},[_c('div',{staticClass:"labels type2"},[_c('span',[_vm._v("Shipping address fraud")])])]),_c('div',{staticClass:"col-4 my-1"},[_c('div',{staticClass:"labels type4"},[_c('span',[_vm._v("Refund Order")])])]),_c('div',{staticClass:"col-4 my-1"},[_c('div',{staticClass:"labels type3"},[_c('span',[_vm._v("Customer Creation")])])]),_c('div',{staticClass:"col-4 my-1"},[_c('div',{staticClass:"labels type5"},[_c('span',[_vm._v("Unconfirmed Order")])])]),_c('div',{staticClass:"col-4 my-1"},[_c('div',{staticClass:"labels type6"},[_c('span',[_vm._v("Partial Refund")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"my-1"},[_c('strong',[_vm._v("Orders & Refunds")])])
}]

export { render, staticRenderFns }